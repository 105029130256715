import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import { Heading1, Paragraph } from '../components/text';


function OffersBanner() {
  return (
    <Container fluid>
      <Row>
        <Col className="px-md-6">
          <Heading1 sm={3.5} md={6}>
            <span className="d-block d-md-inline">WAY</span> more value
          </Heading1>
        </Col>
      </Row>
      
      <Row className="bg-secondary py-2 mb-2">
        <Col className="px-md-6">
          <Paragraph sm={2} md={1.75} weight={700} className="text-black m-0">
            Take advantage of these great OFFERS
          </Paragraph>
        </Col>
      </Row>
      
      <Row>
        <Col className="px-md-6">
          <Paragraph sm={1} md={1.4} weight={700}>
            Offers available for a limited time only and prices subject to change without notice.
          </Paragraph>
        </Col>
      </Row>
    </Container>
  );
}

export default OffersBanner;
