import styled from 'styled-components';


const UnorderedList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  color: #ffc20d;
  font-size: ${props => props.sm ? `${props.sm}rem` : 'inherit'};
  font-weight: ${props => props.weight || 'inherit'};
  
  li {
    display: inline-block;
  }

  li:not(:last-of-type):after {
    content: '\u2022';
    color: #fff;
    margin: 0 0.5rem;
  }

  @media (min-width: 768px) {
    font-size: ${props => props.md ? `${props.md}rem` : 'inherit'};

    li:not(:last-of-type):after {
      margin: 0 1rem;
    }
  }
`;

export default UnorderedList;
