import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'reactstrap';

import UnorderedList from '../components/UnorderedList';
import Image from '../components/Image';
import Layout from '../components/Layout';
import OffersBanner from '../components/OffersBanner';


function Offers({ data }) {
  const { edges } = data.allDealsJson;

  return (
    <Layout title="Offers" className="py-4 py-md-7">
      <OffersBanner />

      <Container fluid className="px-md-6">
        <UnorderedList sm={1} md={1.5} weight={600}>
          {edges.map(({ node }, index) => (
            <li key={index}>
              <a href={`#${node.slug}`} className="text-reset">{node.name}</a>
            </li>
          ))}
        </UnorderedList>

        {edges.map(({ node }, index) => {
          if (node.href) {
            return (
              <a href={node.href}>
                <Image
                  key={index}
                  sm={node.image.src.sizes.sm.src}
                  md={node.image.src.sizes.md.src}
                  id={node.slug}
                  alt={node.name}
                  className="d-block my-4 my-md-6 mx-auto w-100"
                  style={{ maxWidth: `1000px` }}
                />
              </a>
            );
          }

          return (
            <Image
              key={index}
              sm={node.image.src.sizes.sm.src}
              md={node.image.src.sizes.md.src}
              id={node.slug}
              alt={node.name}
              className="d-block my-4 my-md-6 mx-auto w-100"
              style={{ maxWidth: `1000px` }}
            />);
        })}
      </Container>
    </Layout>
  );
}

export const query = graphql`
  {
    allDealsJson {
      edges {
        node {
          slug
          name
          image {
            src {
              sizes: childImageSharp {
                sm: resize(width: 640, toFormat: PNG, pngQuality: 90) {
                  src
                  width
                  height
                }
              }
              sizes: childImageSharp {
                md: resize(width: 1000, toFormat: PNG, pngQuality: 90) {
                  src
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Offers;
